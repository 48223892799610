import React from 'react'

import Helmet from 'react-helmet'

import * as routes from 'config/routes'

import styled from 'styled-components'

import colors from 'styles/colors'

import Layout from 'components/Layout'

import {
  HeroContainer,
  MiddleContainer,
  Headline,
  SubHeadline
} from 'components/Landings/Hero'
import VideoView from 'components/Landings/VideoView'
import SignUpButton from 'components/Landings/SignUpButton'
import SectionsView from 'components/Landings/SectionsView'
import Footer from 'components/Landings/Footer'
import InvitationBadge from 'components/Landings/InvitationBadge'

import Compared from './components/_AdventureCompared'
import WorldTravel from './components/_WorldTravel'
import AMExplained from './components/_AMExplained'

const meta = {
  title: 'Adventure Machine - StayCircles',
  description:
    "It's your turn to travel the world. Save $1000s on flights to friends and dream destinations with cheap flight alerts from Adventure Machine.",
  image:
    'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077796/assets/meta/AM_metadata.jpg',
  logo:
    'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png',
  url: 'https://www.staycircles.com/adventure'
}

const AdventureMachine = () => (
  <Layout className='overflow-x-hidden'>
    <Helmet>
      <title>Adventure Machine</title>

      <meta name='description' content={meta.description} />
      <meta name='image' content={meta.logo} />

      <meta itemProp='name' content={meta.title} />
      <meta itemProp='description' content={meta.description} />
      <meta itemProp='image' content={meta.logo} />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={meta.title} />
      <meta name='twitter:description' content={meta.description} />

      <meta name='og:title' content={meta.title} />
      <meta name='og:description' content={meta.description} />
      <meta name='og:image' content={meta.image} />
      <meta name='og:url' content={meta.url} />
      <meta name='og:site_name' content='StayCircles' />
      <meta name='og:type' content='website' />
    </Helmet>

    <Hero />

    <WorldTravel />

    <GetAMButton />

    <AMExplained>
      <GetAMButton />
    </AMExplained>

    <GetAMButton />

    <Compared />

    <GetAMButton />

    <SectionsView sections={['business', 'friendship']} />

    <Footer
      signupTitle='Get Adventure Machine'
      signupUrl={routes.GET_ADVENTURE}
      createAccount
      marginTop
      marginBottom
    />

    <InvitationBadge />
  </Layout>
)

export const Hero = () => {
  return (
    <Container>
      <div className='h-100 ph3 ph4-l'>
        <Headline className='ph3 flex flex-row justify-center items-center center tc mb0'>
          <img
            alt='Adventure Machine'
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,w_640,c_scale/assets/branding/am_logo_text_bold'
            className='v-mid w-80 w-50-ns'
            style={{ maxWidth: 640 }}
          />
        </Headline>

        <SubHeadline>
          Save hundreds of dollars on <br className='dn db-l' />
          flights to dream destinations.
        </SubHeadline>

        <MidContainer className='w-100 mt4 flex flex-row justify-around items-center'>
          <SideImage
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_deal_details_iphone'
            className='dn db-l'
          />

          <VideoView videoUrl='https://www.youtube.com/embed/vgPMR3-rsbQ?rel=0&autoplay=1'>
            {({ onClick }) => (
              <VideoImage
                src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1518133185/assets/Video_-_Jones_dharya.png'
                onClick={onClick}
                className='w-60 w-auto-l h-100-l pointer'
                title='Meet Jones'
                alt='Meet Jones'
              />
            )}
          </VideoView>

          <SideImage
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_deals_feed_iphone'
            className='dn db-l'
          />
        </MidContainer>

        <SubHeadline>Fly to each other.</SubHeadline>

        <GetAMButton
          style={{ width: 'auto', display: 'inline-block' }}
          className='ph5 b'
        />
      </div>
    </Container>
  )
}

const GetAMButton = props => (
  <SignUpButton
    title='Get Adventure Machine'
    url={routes.GET_ADVENTURE}
    showLogin={false}
    showIfLoggedIn={false}
    style={{ width: 'auto', display: 'inline-block' }}
    className='ph5 b'
    {...props}
  />
)

const Container = styled(HeroContainer)`
  text-align: center;
`

const MidContainer = styled(MiddleContainer)`
  @media (max-width: 767px) {
    .middle {
      margin-bottom: 0;
    }
  }
`

const SideImage = styled.img`
  width: 15%;
`

const VideoImage = styled.img`
  border: 1px solid ${colors.blue};
  border-radius: 8px;
`

export default AdventureMachine
