import React from 'react'

import { Link as GatsbyLink } from 'gatsby'

import { Location, Link } from '@reach/router'

import { AuthProvider } from 'stores/auth'

import * as routes from 'config/routes'

import styled from 'styled-components'

import colors from 'styles/colors'

const SignUpButton = ({
  title = 'Get StayCircles',
  url,
  showLogin = false, // true when app is ready
  showIfLoggedIn = true,
  ...props
}) => {
  return (
    <Location>
      {({ location }) => {
        // Uncomment when app is ready
        let signupUrl = url || routes.GET_APP // `${location.pathname}${routes.SIGNUP}`

        const hasInvite = location.search.match(/invitation_code/)
        if (hasInvite) {
          signupUrl = routes.preserveQuery(routes.CREATE_ACCOUNT)
        }

        return (
          <AuthProvider>
            {store => {
              if (showIfLoggedIn && store.isLoggedIn()) {
                return (
                  <Button as={GatsbyLink} to={routes.APP}>
                    Explore your map
                  </Button>
                )
              }

              return (
                <div className='tc'>
                  <Button to={signupUrl} {...props}>
                    {title}
                  </Button>

                  {showLogin && (
                    <SmallLink>
                      or{' '}
                      <Link to={`${location.pathname}${routes.LOGIN}`}>
                        log in
                      </Link>
                    </SmallLink>
                  )}
                </div>
              )
            }}
          </AuthProvider>
        )
      }}
    </Location>
  )
}

const Button = styled(Link)`
  display: block;
  background-color: ${colors.darkBlue};
  width: 174px;
  height: 38px;
  line-height: 38px;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin: 20px auto 0;
  text-decoration: none;

  &:hover {
    background-color: ${colors.darkerBlue};
    color: white;
  }

  @media (min-width: 320px) {
    font-size: calc(14px + 10 * ((100vw - 320px) / 1580));
    width: calc(174px + 116 * ((100vw - 320px) / 1580));
    height: calc(24px + 26 * ((100vh - 480px) / 420));
    line-height: calc(24px + 26 * ((100vh - 480px) / 420));
  }

  @media (min-width: 768px) {
    margin: 4.4vh auto 0;
    height: calc(38px + 26 * ((100vh - 480px) / 420));
    line-height: calc(38px + 26 * ((100vh - 480px) / 420));
  }
`

const SmallLink = styled.p`
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 300;
  text-align: center;
  margin: 16px auto 0;

  @media (min-width: 320px) {
    font-size: calc(14px + 4 * ((100vw - 320px) / 1580));
  }

  @media (min-width: 768px) {
    background: #fff;
    font-size: 2.1vmin;
    width: 100px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
  }

  a {
    color: ${colors.darkBlue};
    text-decoration: none;

    &:hover {
      color: ${colors.darkerBlue};
    }
  }
`

export default SignUpButton
