import React, { Fragment } from 'react'

import * as routes from 'config/routes'

import styled, { css } from 'styled-components'

import { Title, Text } from './BusinessOrPleasure'
import ButtonLink from './ButtonLink'

const sectionsMap = {
  business: {
    background: 'business',
    title: 'Business Stays',
    body: (
      <Fragment>
        StayCircles is different. On each stay,
        <br />
        the business traveler and host each earn money,
        <br />
        and the company saves 33% off the market rate.
        <br />
        <br />
        Everybody wins.
      </Fragment>
    ),
    buttonText: 'Explore Business Stays',
    path: routes.preserveQuery(routes.BUSINESS)
  },
  friendship: {
    background: 'friendship',
    title: 'Friendship Stays',
    body: (
      <Fragment>
        Friendship Stays make finding a place
        <br />
        to stay with a friend or a friend-of-a-friend
        <br />
        easy and organized.
        <br />
        <br />
        Plus, it’s completely free.
      </Fragment>
    ),
    buttonText: 'Explore Friendship Stays',
    path: routes.preserveQuery(routes.FRIENDSHIP)
  },
  adventure: {
    background: 'adventure',
    title: 'Adventure Machine',
    body: (
      <Fragment>
        Cheap flights to epic destinations directly to your phone.
        <br /> <br />
        See and stay with friends worldwide.
        <br />
        <br className='dn db-ns' />
        <br className='dn db-ns' />
      </Fragment>
    ),
    buttonText: 'Explore Adventure Machine',
    path: routes.preserveQuery(routes.ADVENTURE)
  }
}

const SectionsView = ({ sections = [] }) => {
  return (
    <Container className='tc mt4 mt6-ns center'>
      {sections.map(key => {
        const section = sectionsMap[key]

        if (!section) return null

        return <Section key={key} {...section} />
      })}

      <div style={{ clear: 'both' }} />
    </Container>
  )
}

const Section = ({ background, title, body, buttonText, path }) => (
  <SectionContainer className='h-100'>
    <SectionCover background={background} className='w-100 h-100 cover'>
      <div className='white pv5 ph4 tc h-100'>
        <div className='dt h-100 center'>
          <div className='dtc v-mid'>
            <Title className='mt0 fw7 lh-title mt6-ns'>{title}</Title>

            <Text className='fw4 mv4 lh-copy'>{body}</Text>

            <ButtonLink to={path}>{buttonText}</ButtonLink>
          </div>
        </div>
      </div>
    </SectionCover>
  </SectionContainer>
)

const Container = styled.div`
  height: 70vh;

  @media (max-width: 870px) {
    height: auto;
  }
`

const SectionContainer = styled.div`
  margin-left: -1px;
  width: 100%;

  @media (min-width: 870px) {
    width: 50%;
    float: left;
  }
`

const SectionCover = styled.div`
  ${props =>
    props.background === 'business' &&
    css`
      background: url('https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto:best/v1507604504/assets/bsStays.jpg')
        no-repeat center center;
    `}

  ${props =>
    props.background === 'friendship' &&
    css`
      background: url('https://res.cloudinary.com/staycircles/image/upload/b_rgb:000000,f_auto,o_60,q_auto:best/v1516768662/assets/FriendshipStays.jpg')
        no-repeat center center;
    `}

  ${props =>
    props.background === 'adventure' &&
    css`
      background: url('https://res.cloudinary.com/staycircles/image/upload/b_rgb:000000,f_auto,o_60,q_auto:best/v1516768663/assets/AdventureMachine.jpg')
        no-repeat center center;
    `}
`

export default SectionsView
