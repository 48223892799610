import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Text } from 'components/Landings/Explained'

const WorldTravel = () => (
  <Container>
    <Header>
      <Logo src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_landing/am_sc_logo_combined_white' />

      <TextWithShadow size='large' weight='900'>
        YOU WILL TRAVEL THE WORLD
      </TextWithShadow>

      <TextWithShadow>Fly to and stay with each other.</TextWithShadow>
    </Header>

    <PhoneSection>
      <Row>
        <PhoneColumn>
          <Phone
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_deals_feed_iphone'
            className='db center'
          />

          <TextWithShadow size='medium' weight='900'>
            Stop wasting <br /> your time.
          </TextWithShadow>

          <TextWithShadow>
            You have important things to do. We will find the cheap deals and
            send them to you.
          </TextWithShadow>
        </PhoneColumn>

        <PhoneColumn>
          <Phone
            src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/assets/am_deal_details_iphone'
            className='db center'
          />

          <TextWithShadow size='medium' weight='900'>
            Normal flights: $600
            <br />
            Flights we find: $305
          </TextWithShadow>

          <TextWithShadow>
            Take that $295 and use it on something awesome
          </TextWithShadow>
        </PhoneColumn>
      </Row>
    </PhoneSection>
  </Container>
)

const TextWithShadow = props => (
  <Text color={colors.white} shadow='1px 1px 8px #333' {...props} />
)

const Container = styled.div`
  background: url('https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1537918213/assets/eiffel-tower-3349075_rdz6wg.jpg')
    no-repeat center center;
  background-size: cover;
  position: relative;
  padding: 10px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Logo = styled.img`
  width: 400px;
  margin: 50px auto;
`

const PhoneSection = styled.div`
  margin: 36px 0 100px;
`

const Row = styled.div`
  @media (max-width: 767px) {
    width: 80%;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > * {
      width: 30%;
    }
  }
`

const PhoneColumn = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`

const Phone = styled.img`
  height: 400px;
  width: 202px;
`

export default WorldTravel
