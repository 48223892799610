import React from 'react'

import styled, { css } from 'styled-components'

import colors from 'styles/colors'

import Icon from 'components/Icon'

export const Logo = styled(Icon).attrs({ name: 'sc-logo' })`
  color: #fff;
  max-width: 100%;
  height: auto;
`

export const Row = styled.div.attrs({
  className: props => `w-100 w-80-ns center dt fw3 ${props.className || ''}`
})`
  border-collapse: separate;
  border-spacing: 1rem 0.3rem;
  max-width: 70rem;
  font-size: 1rem;

  ${props =>
    props.gray &&
    `
    > div {
      background: #eee;
    }
  `} @media (min-width: 992px) {
    font-size: 1.125rem;
  }

  @media (min-width: 1120px) {
    font-size: 1.25rem;
  }

  @media (min-width: 1250px) {
    font-size: 1.5rem;
  }

  @media (max-width: 600px) {
    border-spacing: 0.3rem;
  }
`

export const TopRow = styled(Row)`
  border-spacing: 1rem 0;

  @media (max-width: 600px) {
    border-spacing: 0.3rem 0;
  }
`

const RowHeaderWrapper = styled.div.attrs({
  className: props => `dtc h-100 v-mid ${props.className || 'pa3'}`
})`
  width: 40%;

  @media (min-width: 870px) {
    width: 50%;
  }
`

export const RowHeader = ({ children, ...props }) => (
  <RowHeaderWrapper {...props}>
    <p className='ma0'>{children}</p>
  </RowHeaderWrapper>
)

export const RowCell = styled.div.attrs({
  className: props => `dtc h-100 v-mid ${props.className || 'pa3 tc'}`
})`
  width: calc(60% / 3);

  @media (min-width: 870px) {
    width: calc(50% / 3);
  }

  background-color: ${props =>
    props.bg || (props.gray && '#eee') || 'transparent'};
`

export const HeaderArrowContainer = styled.div`
  height: 30px;
  position: relative;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  width: calc(60% / 3);

  @media (min-width: 870px) {
    width: calc(50% / 3);
  }
`

export const HeaderArrowChild = styled.div`
  position: absolute;
  left: 50%;
  width: 100%;
  height: 60px;
  top: -55%;
  border: 90px solid transparent;
  border-top-color: ${props => (props.blue ? colors.darkBlue : '#eee')};
  transform: rotate3d(1, 0, 0, 70deg) translate(-50%, -70%);
`

export const HeaderArrow = ({ blue, className }) => (
  <HeaderArrowContainer className={className}>
    <HeaderArrowChild blue={blue} />
  </HeaderArrowContainer>
)

const checkmarkCss = css`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 30px;
  color: #fff;

  @media (min-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media (min-width: 992px) {
    width: 2rem;
    height: 2rem;
  }
`

export const CheckmarkIcon = styled(Icon).attrs({ name: 'checkmark-i' })`
  ${checkmarkCss}

  background-color: ${colors.darkBlue};
  padding: 0.4rem;
`

export const CrossIcon = styled(Icon).attrs({ name: 'close' })`
  ${checkmarkCss}

  background-color: ${colors.darkRed};
  padding: 0.4rem;
`
