import { Link } from 'gatsby'

import styled, { css } from 'styled-components'

import colors from 'styles/colors'

const blueBtn = css`
  color: ${colors.white};
  background-color: ${colors.blue};

  &:hover {
    color: ${colors.offwhite};
    background-color: ${colors.darkBlue};
  }
`

const whiteBtn = css`
  color: ${colors.blue};
  background-color: ${colors.white};
  border: 1px solid ${colors.blue};

  &:hover {
    color: ${colors.darkBlue};
    background-color: ${colors.offwhite};
  }
`

const spread = css`
  min-width: 80vw;

  @media (min-width: 768px) {
    min-width: 30vw;
  }

  @media (min-width: 1280px) {
    min-width: 24vw;
  }

  @media (min-width: 1600px) {
    min-width: 20vw;
  }
`

const ButtonLink = styled(Link).attrs({
  className: 'link dib mv3'
})`
  font-weight: 600;
  font-size: 4vmin;
  text-align: center;
  padding: 1vh 4vw;
  min-width: 36.4vw;
  transition: all 0.25s ease-in-out;
  border-radius: 6px;

  ${props => (props.blue ? blueBtn : whiteBtn)} @media (max-width: 480px) {
    padding: 1.6vh 4vw;
    min-width: 80vw;
  }

  @media (min-width: 768px) {
    font-size: 2.1vmin;
    padding: 2vh 1.4vw;
    min-width: 30vw;
  }

  @media (min-width: 1080px) {
    min-width: 18vw;
  }

  @media (min-width: 1280px) {
    min-width: 14.3vw;
  }

  @media (min-width: 1366px) {
    position: relative;
  }

  ${props => props.spread && spread};
`

export default ButtonLink
