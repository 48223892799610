import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { SectionHeadline } from 'components/Landings/Hero'
import {
  Logo,
  Row as OriginalRow,
  TopRow as OriginalTopRow,
  RowHeader,
  RowCell,
  HeaderArrowContainer as OriginalHeaderArrowContainer,
  HeaderArrowChild as OriginalHeaderArrowChild,
  CheckmarkIcon,
  CrossIcon
} from 'components/Landings/ComparedTable'

const Row = styled(OriginalRow)`
  @media (min-width: 1920px) {
    width: 70vw;
    max-width: 70vw;
  }
`

const TopRow = styled(OriginalTopRow)`
  @media (min-width: 1920px) {
    width: 70vw;
    max-width: 70vw;
  }
`

const HeaderArrowContainer = styled(OriginalHeaderArrowContainer)`
  @media (min-width: 1920px) {
    height: 40px;
  }
`

const HeaderArrowChild = styled(OriginalHeaderArrowChild)`
  @media (min-width: 1920px) {
    border-width: 110px;
  }
`

const HeaderArrow = ({ blue, className }) => (
  <HeaderArrowContainer className={className}>
    <HeaderArrowChild blue={blue} />
  </HeaderArrowContainer>
)

const Compared = () => {
  return (
    <div className='bg-white mt4 mt6-ns'>
      <SectionHeadline className='ph3 tc'>
        Adventure Machine:{' '}
        <span style={{ color: colors.darkBlue }}>Compared.</span>
      </SectionHeadline>

      <TopRow className='mt5'>
        <RowHeader className='pa3-ns' />

        <RowCell gray className='tc pa2 pa4-l f7 f6-ns b'>
          Other <br /> “Cheap Flight” email subscriptions
        </RowCell>

        <RowCell bg={colors.darkBlue} className='tc pa3-ns o-70'>
          <span>
            <Logo className='mb2' style={{ width: '80%' }} />
            <span className='db white b'>FREE</span>
          </span>
        </RowCell>

        <RowCell bg={colors.darkBlue} className='tc pa3-ns'>
          <span>
            <Logo className='mb2' style={{ width: '80%' }} />
            <span className='db white b'>ADVENTURER</span>
          </span>
        </RowCell>
      </TopRow>

      <TopRow className='lh-solid'>
        <RowHeader className='pa3-ns' />

        <HeaderArrow />
        <HeaderArrow blue className='o-70' />
        <HeaderArrow blue />
      </TopRow>

      <Row gray>
        <RowHeader>
          <span className='b'>Cheap international flight deal alerts</span>{' '}
          saving you hundreds of dollars per flight
        </RowHeader>

        <RowCell>
          <CheckmarkIcon className='o-70' />
        </RowCell>
        <RowCell>
          <CheckmarkIcon className='o-70' />
        </RowCell>
        <RowCell>
          <CheckmarkIcon />
        </RowCell>
      </Row>

      <Row>
        <RowHeader>
          <span className='b'>Book a room</span>, worldwide, with your{' '}
          <span className='b'>friends and friends-of-friends</span>
        </RowHeader>

        <RowCell>
          <CrossIcon className='o-70' />
        </RowCell>
        <RowCell>
          <CheckmarkIcon className='o-70' />
        </RowCell>
        <RowCell>
          <CheckmarkIcon />
        </RowCell>
      </Row>

      <Row gray>
        <RowHeader>
          <span className='b'>Custom</span> departure{' '}
          <span className='b'>airports</span> for your flight deal alerts
        </RowHeader>

        <RowCell>
          <span style={{ color: colors.red }} className='b f5 f4-ns'>
            Not Worldwide
          </span>
        </RowCell>
        <RowCell>
          <CheckmarkIcon className='o-70' />
        </RowCell>
        <RowCell>
          <CheckmarkIcon />
        </RowCell>
      </Row>

      <Row>
        <RowHeader>
          Renews automatically, <span className='b'>cancel anytime</span>, no
          ads
        </RowHeader>

        <RowCell>
          <CheckmarkIcon className='o-70' />
        </RowCell>
        <RowCell>
          <CheckmarkIcon className='o-70' />
        </RowCell>
        <RowCell>
          <CheckmarkIcon />
        </RowCell>
      </Row>

      <Row gray>
        <RowHeader>
          Get <span className='b'>100%</span> of all Adventure Machine flight
          deal alerts from your depature airports
        </RowHeader>

        <RowCell>
          <CrossIcon className='o-70' />
        </RowCell>
        <RowCell>
          <span style={{ color: colors.red }} className='b f4'>
            33%
          </span>
        </RowCell>
        <RowCell>
          <CheckmarkIcon />
        </RowCell>
      </Row>

      <Row>
        <RowHeader>
          <span className='b'>Help & support</span> via{' '}
          <span className='b'>live chat</span> with the StayCircles team.
        </RowHeader>

        <RowCell>
          <CrossIcon className='o-70' />
        </RowCell>
        <RowCell>
          <CrossIcon className='o-70' />
        </RowCell>
        <RowCell>
          <CheckmarkIcon />
        </RowCell>
      </Row>

      <Row gray>
        <RowHeader style={{ background: 'transparent' }} />

        <RowCell>
          <span style={{ color: colors.red }} className='b f6 f5-m f4-l'>
            $39 / year
          </span>
        </RowCell>

        <RowCell>
          <span style={{ color: colors.blue }} className='b f6 f5-m f4-l o-70'>
            Free
          </span>
        </RowCell>

        <RowCell>
          <span style={{ color: colors.blue }} className='b f6 f5-m f4-l'>
            Less than the competition!
          </span>
        </RowCell>
      </Row>
    </div>
  )
}

export default Compared
