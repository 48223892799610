import React from 'react'

import * as routes from 'config/routes'

import styled from 'styled-components'

import colors from 'styles/colors'

import ButtonLink from './ButtonLink'

import { SectionHeadline } from 'components/Landings/Hero'

const BusinessOrPleasure = () => {
  return (
    <Container className='w-100 mt6 mt0-l'>
      <SectionHeadline className='db dn-l tc'>
        Business or Pleasure?
      </SectionHeadline>

      <div className='fl-l w-100 w-50-l h-100'>
        <BusinessContainer className='w-100 h-100 cover'>
          <Overlay className='white pv5 ph4 tc h-100'>
            <div className='dt h-100 center'>
              <div className='dtc v-mid'>
                <Title className='mt0 lh-title mt6-l'>Business Stay</Title>

                <Text className='mv4'>
                  StayCircles is different. On each stay, <br />
                  the business traveler and host each earn money, <br />
                  and the company saves 33% off the market rate.
                </Text>

                <Text className='mb4'>Everybody wins.</Text>

                <ButtonLink to={routes.preserveQuery(routes.BUSINESS)}>
                  Explore Business Stays
                </ButtonLink>
              </div>
            </div>
          </Overlay>
        </BusinessContainer>
      </div>

      <MainTitle className='absolute white f1 dn dib-l left-0 right-0 center mt5 tc'>
        Business or Pleasure?
      </MainTitle>

      <div className='fl-l w-100 w-50-l h-100'>
        <FriendsContainer className='w-100 h-100 cover'>
          <Overlay className='pv5 ph4 tc h-100'>
            <div className='dt h-100 center'>
              <div className='dtc v-mid'>
                <Title className='mt0 lh-title mt6-l'>Friendship Stay</Title>

                <Text className='mb4'>
                  Friendship Stays make finding a place <br />
                  to stay with a friend or a friend-of-a-friend <br />
                  easy and organized.
                </Text>

                <Text className='mb4'>Plus, it’s completely free.</Text>

                <ButtonLink to={routes.preserveQuery(routes.FRIENDSHIP)}>
                  Explore Friendship Stays
                </ButtonLink>
              </div>
            </div>
          </Overlay>
        </FriendsContainer>
      </div>
    </Container>
  )
}

const Container = styled.div`
  height: 80vh;

  @media (max-width: 959px) {
    height: auto;
  }
`

const SectionContainer = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
`

const BusinessContainer = styled(SectionContainer)`
  background: url('https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto:best/v1507604504/assets/bsStays.jpg')
    no-repeat center center;
`

const FriendsContainer = styled(SectionContainer)`
  background: url('https://res.cloudinary.com/staycircles/image/upload/b_rgb:000000,f_auto,o_60,q_auto:best/v1516768662/assets/FriendshipStays.jpg')
    no-repeat center center;
`

const Overlay = styled.div`
  background-color: rgba(19, 65, 117, 0.3);
`

const MainTitle = styled(SectionHeadline)`
  margin-top: 13.2vh;
  font-weight: 400;
`

export const Title = styled.h2`
  color: ${props => (props.black ? colors.blackRGBA(0.8) : '#fff')};
  font-size: 2.5vw;
  font-weight: ${props => props.fw || 600};

  @media (max-width: 950px) {
    font-size: 22px;
  }
`

export const Text = styled.p`
  color: ${props => (props.black ? colors.blackRGBA(0.8) : '#fff')};
  font-size: 16px;
  font-weight: ${props => props.fw || 400};

  @media (min-width: 320px) {
    font-size: calc(16px + 4 * ((100vw - 320px) / 1600));
  }

  @media (min-width: 1920px) {
    font-size: 2.8vmin;
  }
`

export default BusinessOrPleasure
